/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    em: "em",
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    code: "code",
    pre: "pre",
    h3: "h3",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Center, Warning} = _components;
  if (!Center) _missingMdxReference("Center", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/a9dc5d9f65de8b6e179f822487db0261/hello-there.gif",
    alt: "Obi wan hello there"
  })), "\n", React.createElement(Center, null, React.createElement(_components.em, null, "Yes I'm opening with Star Wars gifs this is my own blog you can't stop me.")), "\n", React.createElement(_components.p, null, "Hi! In this post, I'm going to run through what I did to get this site onto the internet - the intent is to be a useful guide for anybody who's looking to do something similar, but as I'm writing this at the same time as I build everything, it'll likely include a bit of stream-of-consciousness on the process!"), "\n", React.createElement(_components.p, null, "The best bit for you is that you get to skip the bits where I called AWS services foul things because I'd configured something wrong, and get right to the bits that work, with some explanations where things might have had me Googling or I had to backtrack on decisions."), "\n", React.createElement(_components.h1, {
    id: "the-stack",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-stack",
    "aria-label": "the stack permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Stack"), "\n", React.createElement(_components.h2, {
    id: "gatsby",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gatsby",
    "aria-label": "gatsby permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gatsby"), "\n", React.createElement(_components.p, null, "I won't be focusing too much on this in this post, but to get what you're seeing built, I followed the ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/tutorial/part-1/"
  }, "Gatsby tutorial"), " for a bit, threw a strop when I realised how much Gatsby loves GraphQL, and then begrudgingly came back when I realised that building much else was going to be a fair bit more work than I wanted to do."), "\n", React.createElement(_components.p, null, "Once I finished the guide I did a couple of modifications so that I could messily cram it all into a blog template theme built by ", React.createElement(_components.a, {
    href: "https://twitter.com/3rdwave_themes"
  }, "Xiaoying Riley"), " - you can find their templates ", React.createElement(_components.a, {
    href: "https://themes.3rdwavemedia.com/bootstrap-templates/personal/devblog-free-bootstrap-5-blog-template-for-developers/"
  }, "here"), ", which I'm going to hopefully be able to build and throw into AWS."), "\n", React.createElement(_components.p, null, "At a certain point, I realised that the routing wouldn't work without a plugin called ", React.createElement(_components.code, null, "gatsby-plugin-s3"), ", which I gave the following config in my"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "gatsby-config")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  resolve: `gatsby-plugin-s3`,\n  options: {\n    bucketName: \"<BUCKET_NAME>\",\n    protocol: \"https\",\n    hostname: \"<DOMAIN_NAME>\"\n  }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "aws",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#aws",
    "aria-label": "aws permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "AWS"), "\n", React.createElement(_components.p, null, "Most of my interactions with AWS are through Terraform at the moment (and then a bit of ", React.createElement(_components.a, {
    href: "https://www.lastweekinaws.com/blog/clickops/"
  }, "ClickOps"), " when I'm confused or trying to ascertain the state of a system)."), "\n", React.createElement(_components.h3, {
    id: "route53",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#route53",
    "aria-label": "route53 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Route53"), "\n", React.createElement(_components.p, null, "Route53 is AWS' DNS service, which allows me to manage where my domain points to. By pointing my name servers at it, I can then automate setting up new records via Terraform."), "\n", React.createElement(Warning, null, "One caveat to doing this is that currently the control planes for Route53 are located in AWS 'us-east-1' zone, which has seen a couple of major outages in the last year. If you have critical applications in production and DNS changes are part of your disaster recovery plan then make sure to consider this whilst building!"), "\n", React.createElement(_components.h3, {
    id: "s3",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#s3",
    "aria-label": "s3 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "S3"), "\n", React.createElement(_components.p, null, "AWS' Simple Storage Service serves as a suitable service to situate my very serious site. Whilst primarily advertised as a 'file storage' service, S3 is one of my favourite AWS services because of it's versatility. In this instance, we're going to use it as the place where we host our site, meaning we don't have to spin up and manage any pesky servers. I'm hoping this pleases the Serverless ~~cult~~ crowd."), "\n", React.createElement(_components.h3, {
    id: "cloudfront",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cloudfront",
    "aria-label": "cloudfront permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cloudfront"), "\n", React.createElement(_components.p, null, "Cloudfront is a CDN that helps tie together the entire project by making sure my site is available as close to users as it can be in 'edge locations', and caching that site at those edge locations. This helps me by reducing latency for users, and reducing the amount of requests that need to fetch information from the S3 bucket itself, which is a massive cost saver."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "the-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-guide",
    "aria-label": "the guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Guide"), "\n", React.createElement(_components.p, null, "In broad steps, I'll be walking through:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Managing my externally purchased domain using AWS Route53"), "\n", React.createElement(_components.li, null, "Hosting a Gatsby site in S3"), "\n", React.createElement(_components.li, null, "Serving that site from Cloudfront"), "\n"), "\n", React.createElement(_components.p, null, "So let's get into it."), "\n", React.createElement(_components.h3, {
    id: "my-setup",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#my-setup",
    "aria-label": "my setup permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "My setup"), "\n", React.createElement(_components.p, null, "This is not prescriptive, but if you're following along then this is how I'm doing things:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Using Terraform to manage infrastructure, installed via ", React.createElement(_components.a, {
    href: "https://github.com/tfutils/tfenv"
  }, "tfenv"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Using a .tfvars file that I feed into my ", React.createElement(_components.code, null, "terraform apply"), " for things like domain etc that might get repeated"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Running everything in a new AWS Organizations sub account"), "\n", React.createElement(_components.li, null, "The AWS CLI"), "\n", React.createElement(_components.li, null, "Authenticating using ", React.createElement(_components.a, {
    href: "https://docs.commonfate.io/granted/introduction"
  }, "Granted")), "\n"), "\n", React.createElement(_components.p, null, "Here's what my ", React.createElement(_components.code, null, "variables.tf"), " file looks like:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "variable domain_name {\n  type = string\n}\n\nvariable bucket_name {\n  type = string\n}\n\nvariable environment {\n  type = string\n}\n")), "\n", React.createElement(_components.p, null, "I then fill out these records into a ", React.createElement(_components.code, null, ".tfvars"), " file and use them ", React.createElement(_components.a, {
    href: "https://www.terraform.io/language/values/variables#variable-definitions-tfvars-files"
  }, "as shown here")), "\n", React.createElement(_components.h1, {
    id: "managing-my-domain-through-aws",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#managing-my-domain-through-aws",
    "aria-label": "managing my domain through aws permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Managing my domain through AWS"), "\n", React.createElement(_components.p, null, "Before setting up this site, I had purchased the domain https://man-yells-at.cloud through Namecheap (as they seem to always be decently priced, making any DNS changes isn't frustrating as hell, and they're familiar)."), "\n", React.createElement(_components.p, null, "I could manually point domains at things later on in the guide, but I like being able to manage everything through Terraform, so I'm going to point my Namecheap domain at AWS. In order to do this, I'm going to create a little Terraform module to manage a Route53 Hosted Zone. I set up the provider and remote backend in a ", React.createElement(_components.code, null, "main.tf"), " file, and then created a new file -"), "\n", React.createElement(_components.h3, {
    id: "route53-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#route53-1",
    "aria-label": "route53 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Route53"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "route53.tf")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_route53_zone\" \"main\" {\n  name = var.domain_name\n\n  tags = {\n    Name = var.bucket_name\n    Environment = var.environment\n  }\n}\n\noutput \"nameservers\" {\n  value = aws_route53_zone.main.name_servers\n}\n")), "\n", React.createElement(_components.p, null, "And running ", React.createElement(_components.code, null, "terraform apply"), ". I included the output so that I'd get something that looks like this:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 684px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/6b6eb11d55f6f3c4fe80df266bb0db7d/2c288/nameservers.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 34.333333333333336%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1ElEQVQoz5WQ3Y6DIBCFUaxSEDCymE2ttQFFTbPZrPv+z3Y2kL1oelUvTs78ZL6ZDGm/DM6fHCo0aL8NxFBDzRp6aSAnjZMuIb1C+/MBZs8Qtxrm10IFDWYZmkeb+tHzUw4inETtJXJOQQh5T9m/XmvRzdVAXxoIJUBzCkopGGMoy/L9Bc+6Xnq4u8MSFgzDgHEcMU1TcmstiqI4BpRSwjmHbdsQQsC+7/Dep1rMu647DoyAOByh67qm6+Z5Rt/3yLLsODD+TAgBznnymMe4qqrDP/wDcBGcGeA1vdIAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Terminal showing a set of AWS nameservers as the output of a Terraform apply\"\n        title=\"Terminal showing a set of AWS nameservers as the output of a Terraform apply\"\n        src=\"/static/6b6eb11d55f6f3c4fe80df266bb0db7d/2c288/nameservers.png\"\n        srcset=\"/static/6b6eb11d55f6f3c4fe80df266bb0db7d/5a46d/nameservers.png 300w,\n/static/6b6eb11d55f6f3c4fe80df266bb0db7d/0a47e/nameservers.png 600w,\n/static/6b6eb11d55f6f3c4fe80df266bb0db7d/2c288/nameservers.png 684w\"\n        sizes=\"(max-width: 684px) 100vw, 684px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "I then used these to change the nameservers settings in Namecheap. To check to make sure everything propagated and I used the correct nameservers, I created a TXT record by adding this block:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "route53.tf")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_route53_record\" \"propagation_check\" {\n  zone_id = aws_route53_zone.main.zone_id\n  name    = \"test.${var.domain_name}\"\n  type    = \"TXT\"\n  ttl     = 300\n  records = [\"teststring\"]\n}\n")), "\n", React.createElement(_components.p, null, "And applying again. After a couple of minutes, I ran"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "dig -t txt test.man-yells-at.cloud\n")), "\n", React.createElement(_components.p, null, "And saw the record I'd just set. So far, so good."), "\n", React.createElement(_components.h1, {
    id: "creating-an-s3-bucket-and-uploading-my-site",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#creating-an-s3-bucket-and-uploading-my-site",
    "aria-label": "creating an s3 bucket and uploading my site permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Creating an S3 bucket and uploading my site"), "\n", React.createElement(Warning, null, "I had to come back to this bit after a bit of a reminder as to how some types of site operate in S3. This was initially a bucket with absolutely no public read, where our Cloudfront distribution had the permissions necessary to serve the objects in it - a setup that is generally best practice as it means nobody is interacting directly with the bucket. Unfortunately this isn't feasible without breaking the way some routes work in Gatsby, so I'll write a different post about how to do that securely at some point! "), "\n", React.createElement(_components.p, null, "With the above addendum in mind, I switched to making a publicly readable bucket."), "\n", React.createElement(_components.h3, {
    id: "s3-bucket",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#s3-bucket",
    "aria-label": "s3 bucket permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "S3 Bucket"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_s3_bucket\" \"site_bucket\" {\n  bucket = var.bucket_name\n\n  tags = {\n    Name = var.bucket_name\n    Environment = var.environment\n  }\n}\n\nresource \"aws_s3_bucket_acl\" \"site_bucket_acl\" {\n  bucket = aws_s3_bucket.site_bucket.id\n  acl    = \"public-read\"\n}\n\nresource \"aws_s3_bucket_website_configuration\" \"site_config\" {\n  bucket = aws_s3_bucket.site_bucket.id\n\n  index_document {\n    suffix = \"index.html\"\n  }\n\n  error_document {\n    key = \"/404.html\"\n  }\n}\n\nresource \"aws_s3_bucket_public_access_block\" \"public_access_block\" {\n  bucket = aws_s3_bucket.site_bucket.id\n\n  block_public_acls       = false\n  block_public_policy     = false\n  ignore_public_acls      = false\n  restrict_public_buckets = false\n}\n\nlocals {\n  s3_origin_id = \"myS3Origin\"\n}\n")), "\n", React.createElement(_components.p, null, "And another ", React.createElement(_components.code, null, "terraform apply")), "\n", React.createElement(_components.p, null, "Once the bucket and it's corresponding website configuration was set up, it was a simple matter of running the ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/plugins/gatsby-plugin-s3/"
  }, "s3 deploy plugin in Gatsby")), "\n", React.createElement(_components.h1, {
    id: "getting-this-into-cloudfront",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-this-into-cloudfront",
    "aria-label": "getting this into cloudfront permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting this into Cloudfront"), "\n", React.createElement(_components.h2, {
    id: "okay-so-this-got-out-of-hand",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#okay-so-this-got-out-of-hand",
    "aria-label": "okay so this got out of hand permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Okay so this got out of hand..."), "\n", React.createElement(_components.p, null, "This bit is where it got frustrating. At first, as noted earlier - I had intended for the buckets to be locked down and only available via Cloudfront. It quickly became clear due to the way that routing works in Gatsby that this couldn't be the case, and so I slowly had to undo the pieces I had put in place to protect the bucket from direct access."), "\n", React.createElement(_components.p, null, "The most frustrating part of this was the subtle difference (when you're looking at code at least) that ", React.createElement(_components.em, null, "if you serve an S3 bucket on it's website endpoint, it changes from an s3_origin_config to a custom_origin_config which uses some different rules"), ". It's a small gripe, but I am a drama queen and I ", React.createElement(_components.em, null, "will"), " make a mountain out of this molehill."), "\n", React.createElement(_components.h2, {
    id: "anyway-lets-get-building",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#anyway-lets-get-building",
    "aria-label": "anyway lets get building permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Anyway, let's get building"), "\n", React.createElement(_components.p, null, "Firstly, I knew I needed a certificate that I could attach to my Cloudfront distribution, so I made that first. Cloudfront ", React.createElement(_components.em, null, "only accepts certificates made in their ", React.createElement(_components.code, null, "us-east-1"), " zone"), ", so remember this when you're building. I used a separate aliased Terraform provider, and considering how simple it makes things I used the AWS ACM module to set up the certificate - this handily does the minor lifting of validating the certificate using Route53 for me (but if you want to roll your own, Terraform gives you all the component pieces to do so pretty easily!):"), "\n", React.createElement(_components.h3, {
    id: "certificate",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#certificate",
    "aria-label": "certificate permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Certificate"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "certificate.tf")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "provider \"aws\" {\n  alias  = \"us-east-1\"\n  region = \"us-east-1\"\n}\n\nmodule \"acm\" {\n  source  = \"terraform-aws-modules/acm/aws\"\n  version = \"~> 3.0\"\n\n  domain_name  = var.domain_name\n  zone_id      = aws_route53_zone.main.zone_id\n\n  subject_alternative_names = [\n    \"*.${var.domain_name}\",\n  ]\n\n  providers = {\n    aws = aws.us-east-1\n  }\n\n  wait_for_validation = true\n}\n")), "\n", React.createElement(_components.p, null, "Applied that - this can take slightly longer if the DNS validation doesn't happen ", React.createElement(_components.em, null, "straight away"), " - and I had my certificate!"), "\n", React.createElement(_components.h3, {
    id: "cloudfront-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cloudfront-1",
    "aria-label": "cloudfront 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cloudfront"), "\n", React.createElement(_components.p, null, "With the certificate made, I proceeded onto the Cloudfront distribution. This uses the S3 Website Endpoint as an origin (which doesn't accept HTTPS - this revelation took me another short while to figure out as I refreshed to multiple 504 pages). I made sure to include a redirect-to-https in the configuration so that we don't have anybody accessing the site over HTTP:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "cloudfront.tf")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_cloudfront_distribution\" \"s3_distribution\" {\n  origin {\n    domain_name = aws_s3_bucket.site_bucket.website_endpoint\n    origin_id   = local.s3_origin_id\n\n    custom_origin_config {\n      http_port = 80\n      https_port = 443\n      origin_protocol_policy = \"http-only\"\n      origin_ssl_protocols = [\"SSLv3\", \"TLSv1.2\", \"TLSv1.1\", \"TLSv1\"]\n    }\n  }\n\n  enabled             = true\n  is_ipv6_enabled     = true\n  comment             = \"Some comment\"\n  default_root_object = \"index.html\"\n\n  aliases = [ var.domain_name ]\n\n  default_cache_behavior {\n    allowed_methods  = [\"GET\", \"HEAD\", \"OPTIONS\"]\n    cached_methods   = [\"GET\", \"HEAD\"]\n    target_origin_id = local.s3_origin_id\n\n    forwarded_values {\n      query_string = false\n\n      cookies {\n        forward = \"none\"\n      }\n    }\n\n    viewer_protocol_policy = \"redirect-to-https\"\n    min_ttl                = 0\n    default_ttl            = 3600\n    max_ttl                = 86400\n  }\n\n  price_class = \"PriceClass_200\"\n\n  restrictions {\n    geo_restriction {\n      restriction_type = \"none\"\n    }\n  }\n\n  tags = {\n    Environment = var.environment\n  }\n\n  viewer_certificate {\n    acm_certificate_arn = module.acm.acm_certificate_arn\n    ssl_support_method = \"sni-only\"\n  }\n}\n")), "\n", React.createElement(_components.p, null, "Finally, I added one more block to my ", React.createElement(_components.code, null, "route53.tf"), ":"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "route53.tf")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_route53_record\" \"site\" {\n  zone_id = aws_route53_zone.main.zone_id\n  name    = var.domain_name\n  type    = \"A\"\n\n  alias {\n    name = aws_cloudfront_distribution.s3_distribution.domain_name\n    zone_id = aws_cloudfront_distribution.s3_distribution.hosted_zone_id\n    evaluate_target_health = false\n  }\n}\n")), "\n", React.createElement(_components.p, null, "Applying this all took the longest because Cloudfront distributions can take a little while (in their defence, they sort of have to take over the world)"), "\n", React.createElement(_components.p, null, "Once the route had propagated... well, if you're reading this on ", React.createElement(_components.a, {
    href: "https://man-yells-at.cloud"
  }, "https://man-yells-at.cloud"), " then you're looking at it!"), "\n", React.createElement(_components.h1, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.h3, {
    id: "mvp-is-best",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mvp-is-best",
    "aria-label": "mvp is best permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "MVP is best"), "\n", React.createElement(_components.p, null, "Some bits didn't work out the way I wanted, and that's okay! This is meant to be a rough side project that:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "gives me the ability to post ✅"), "\n", React.createElement(_components.li, null, "without having to worry about significant cost or security issues ✅"), "\n", React.createElement(_components.li, null, "doesn't take long to set up and / or maintain ✅"), "\n"), "\n", React.createElement(_components.p, null, "It's hit the minimum of what I set out to do, and if it becomes infeasible to manage later down the line I can spend a bit of time moving the React bits into their own thing, and and making something a bit more fully formed later on. Maybe it's Lambdas!"), "\n", React.createElement(_components.h3, {
    id: "can-we-get-more-friendly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-we-get-more-friendly",
    "aria-label": "can we get more friendly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can we get more friendly?"), "\n", React.createElement(_components.p, null, "Definitely the biggest issue I saw was between the Cloudfront -> S3 connection. Whilst typical setups are well documented, it took a fair bit of exploration and trial-and-error to get what I'd assume is a fairly common outlier up and running. Maybe it doesn't get documented a lot because there are friendlier platforms for Gatsby out there and I added complication by trying to put it on S3?"), "\n", React.createElement(_components.h3, {
    id: "stay-tuned-for-more",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stay-tuned-for-more",
    "aria-label": "stay tuned for more permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stay tuned for more!"), "\n", React.createElement(_components.p, null, "Getting this published once is all well and good, but I'm going to need to automate the deployment. In my next post, I'll build a secure pipeline that authenticates with AWS without needing an access key for deploying changes / new posts on my site."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
